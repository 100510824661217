import { Template as Entity, TemplateDownload } from './template.model';
import { AppState } from '../app-store.state';
import { Injectable } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {IPage, ITableSort} from "../app-store.interfaces";
import {currentPageEntities, TemplateSelectors as Selectors} from "./template.selectors";
import {TemplateActions as Actions} from './template.actions';
import {TemplateService} from "./template.service";
import {Criteria} from "../app-store.types";
import {generateUniqueId, generateUniqueNumberId} from "../app-store.functions";
import {PaginatedResult} from "../app-store-entity.service";
import {filter, first} from "rxjs/operators";
import {ProductPlacement} from "../product-placement/product-placement.model";

@Injectable({ providedIn: 'root' })
export class TemplateFacade {
  all$: Observable<Entity[]> = this.store.pipe(select(Selectors.selectAll));
  isLoading$: Observable<boolean> = this.store.pipe(select(Selectors.isLoading));
  hasError$: Observable<Error | undefined> = this.store.pipe(select(Selectors.hasErrors));
  current$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.currentEntity));
  currentEntities$: Observable<Entity[] | undefined> = this.store.pipe(select(Selectors.currentEntities));
  currentPageEntities$: Observable<Entity[] | undefined> = this.store.pipe(select(Selectors.currentPageEntities));
  currentId$: Observable<number | undefined> = this.store.pipe(select(Selectors.currentEntityId));
  totalPageable$: Observable<number> = this.store.pipe(select(Selectors.totalPageable));
  currentPage$: Observable<IPage> = this.store.pipe(select(Selectors.currentPage));
  currentSort$: Observable<ITableSort | undefined> = this.store.pipe(select(Selectors.currentSort));
  unsavedEntities$: Observable<Entity[]> = this.store.pipe(select(Selectors.selectStoreChanges));
  currentProductCategoryId$: Observable<number | undefined> = this.store.pipe(select(Selectors.selectProductCategoryId));

  currentRelatedEntities$: Observable<Entity[] | undefined> = this.store.pipe(select(Selectors.currentRelatedEntities));
  currentRelatedEntitiesCurrentPage$: Observable<IPage> = this.store.pipe(select(Selectors.currentRelatedEntitiesCurrentPage));
  currentRelatedEntitiesCurrentSort$: Observable<ITableSort | undefined> = this.store.pipe(select(Selectors.currentRelatedEntitiesCurrentSort));

  private _refreshPage = new Subject<void>();
  refreshPage$: Observable<void> = this._refreshPage.asObservable();

  constructor(
    private store: Store<AppState>,
    private templateService: TemplateService,
  ) {

  }

  getTemporaryById(entityId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectTemporaryById(entityId)));
  }

  getTemporaryByProductId(productId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectTemporaryByProductId(productId)));
  }

  clearStore() {
    this.store.dispatch(Actions.clearStore());
  }

  clearPage(): void {
    this.store.dispatch(Actions.clearPage());
  }

  createInStore(template: Entity) {
    try {
      const editableTemplate = { ...template };
      editableTemplate.tempId = template.id ?? generateUniqueNumberId();
      this.store.dispatch(Actions.createInStore({ entity: editableTemplate}))
    } catch (error) {
      console.error('Error in createInStore:', error);
    }
  }

  updateInStore(template: Entity) {
    this.store.dispatch(Actions.updateInStore({ entity: template}))
  }

  removeInStore(template: Entity) {
    this.store.dispatch(Actions.removeInStore({ entity: template}))
  }

  refreshPage(): void {
    this._refreshPage.next();
  }

  setPaginationSettings(page: IPage): void {
    this.store.dispatch(Actions.setPaginationSettings({page}));
    this.refreshPage();
  }

  setSortSettings(tableSort: ITableSort): void {
    this.store.dispatch(Actions.setSortSettings({tableSort}));
  }

  setCurrentId(entityId: number): void {
    this.store.dispatch(Actions.setCurrentEntityId({entityId}));
  }

  setProductCategoryId(productCategoryId: number): void {
    this.store.dispatch(Actions.setProductCategoryId({productCategoryId}));
  }

  selectEntities(entities: Entity[]): void {
    this.store.dispatch(Actions.selectEntities({entities}));
  }
  deselectEntities(entities: Entity[]): void {
    this.store.dispatch(Actions.deselectEntities({entities}));
  }

  deselectAll(): void {
    this.store.dispatch(Actions.deselectAll());
  }

  hasProducts(templateId: number): Observable<boolean> {
    return this.templateService.hasProducts(templateId);
  }

  loadPage(pageNumber: number, pageSize: number, criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.loadPage({criteria, page: {pageNumber, pageSize}}));
  }

  loadPageWithResult(pageNumber: number, pageSize: number, criteria: Criteria = undefined): Observable<PaginatedResult<Entity>> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadPage({page: {pageNumber, pageSize}, criteria, correlationId: correlationId}));
    return this.store.select(Selectors.findByCorrelationId<PaginatedResult<Entity>>(correlationId))
      .pipe(filter((x) => !!x));
  }

  load(criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.load({criteria}));
  }

  loadWithResult(criteria: Criteria = undefined): Observable<Entity[]> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.load({criteria, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  loadSingleWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadSingleIfNecessary({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x), first());
  }

  loadRelatedTemplates(criteria: Criteria = undefined, pageNumber: number, pageSize: number, ): void {
    this.store.dispatch(Actions.loadRelatedTemplates({criteria, page: {pageNumber, pageSize}}));
  }

  create(entity: Entity): void {
    this.store.dispatch(Actions.create({entity}));
  }

  createWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.create({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  createManyWithResult(entities: Entity[], createNewCategory:boolean): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.createMany({entities, createNewCategory, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  update(entity: Entity): void {
    this.store.dispatch(Actions.update({entity, correlationId: generateUniqueId()}));
  }

  updateManyWithResult(entities: Entity[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.updateMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }


  updateWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.update({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  remove(entityId: number): void {
    this.store.dispatch(Actions.remove({entityId}));
  }

  removeWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.remove({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  clone(entity: Entity): void {
    this.store.dispatch(Actions.clone({entity}));
  }

  cloneWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.clone({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  getById(entityId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectById(entityId)));
  }

  downloadTemplateImage$(data : TemplateDownload){
    return this.templateService.downloadTemplates(data);
  }
}
